<template>
  <div>
    <ik-data-table
      ref="table"
      :entity-name="$t('discountRule')"
      :model="model"
      :use-customer-action-only="true"
      icon="mdi-label"
    >
      <template #item.action="{item}">
        <v-list-item
          @click="showTimeFor(item.id)"
        >
          <v-list-item-title>
            {{ $t('setWorkTime') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          @click="showDishFor(item.id)"
        >
          <v-list-item-title>
            {{ $t('setWorkDish') }}
          </v-list-item-title>
        </v-list-item>
      </template>
    </ik-data-table>
    <v-navigation-drawer
      v-model="showDishDialog"
      app
      color="#fafbfc"
      right
      temporary
      touchless
      width="min(calc(100vw - 300px),1000px)"
    >
      <ik-data-table
        v-if="activeSelectDiscount"
        :entity-name="$t('discountDishList')"
        :filter="filter2"
        :fixed-filter="filter2"
        :model="dishModel"
        :use-action="false"
        :use-default-action="false"
        icon="mdi-account-child"
      >
        <template
          #navigation
        >
          <v-btn
            class="mr-4"
            icon
            @click="showDishDialog=false"
          >
            <v-icon large>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
      </ik-data-table>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="showTimeDialog"
      app
      color="#fafbfc"
      right
      temporary
      touchless
      width="min(calc(100vw - 300px),1000px)"
    >
      <ik-data-table
        v-if="activeSelectDiscount"
        :entity-name="$t('timeSetting')"
        :fixed-filter="filter"
        :model="timeModel"
        icon="mdi-account-child"
      >
        <template
          #navigation
        >
          <v-btn
            class="mr-4"
            icon
            @click="showTimeDialog=false"
          >
            <v-icon large>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
      </ik-data-table>
    </v-navigation-drawer>
  </div>
</template>

<script>
  import { IkDataTable } from 'metaflow-js'
  import DiscountRules from '../../../model/discount/DiscountRules'
  import DishesDiscountList from '@/model/dish/DishesDiscountList'
  import DishesDiscountTimeSpan from '@/model/discount/DishesDiscountTimeSpan'

  export default {
    components: {
      IkDataTable,
    },
    data: function () {
      return ({
        model: DiscountRules,
        activeSelectDiscount: null,
        dishModel: DishesDiscountList,
        timeModel: DishesDiscountTimeSpan,
        showTimeDialog: null,
        showDishDialog: null,
      })
    },
    computed: {
      filter: function () {
        return this.activeSelectDiscount ? { discountRuleId: this.activeSelectDiscount } : null
      },
      filter2: function () {
        return this.activeSelectDiscount ? { ruleId: this.activeSelectDiscount } : null
      },
    },
    methods: {
      showTimeFor (id) {
        console.log(id)
        this.activeSelectDiscount = id
        this.showTimeDialog = true
      },
      showDishFor (id) {
        this.activeSelectDiscount = id
        this.showDishDialog = true
      },
    },
  }
</script>
